import pricingList from '../../helpers/pricingList';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const Pricing = () => {
  return (
    <Container className="text-light bg-dark">
      <Row className="m-5 text-center">
        <Col>
          <h2 className="mt-3">Ценообразование</h2>
        </Col>
      </Row>

      <Row className="mt-3 m-1 mb-3">
        {pricingList.map((plan) => (
          <Col md={4} key={plan.id} className="mb-3">
            <Card className="mt-3 left__menu_bg text-light">
              <Card.Header as="h5" className="mt-3 text-light">{plan.name}</Card.Header>
              <Card.Body>
                <Card.Title className="text-gold">{plan.price}</Card.Title>
                <Card.Text>{plan.description}</Card.Text>
                <NavLink to={"/billing"} className="btn btn-primary">Select Plan</NavLink>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Pricing;
