import { NavLink } from 'react-router-dom';
import { Row, Col, Container, Image } from 'react-bootstrap'
import { FaProjectDiagram, FaCode, FaDatabase } from 'react-icons/fa';
import Covid from './MedProjects/Covid';
import BG1 from './../../img/home/home3.jpg'
import Easy from './../../img/home/Easy1.png'
import Nocode from './../../img/home/Nocode.png'
import Spec from './../../img/home/spec.png'
import AML from './../../img/home/aml.jpg'
import Partnership from './../../img/home/Partnership1.jpg'
import Endeavour from './../../img/home/endeavour.jpg'
import World from './../../img/home/world.jpg'


const Home = () => {
    return (
        <>
            <main className="container-fluid ">
                <div className="bg-container mb-5">
                    <img width="100%" height="auto" alt="" loading="lazy" decoding="async" data-nimg="1" className="" src={BG1} />
                    <div className="home__text" >
                        <Col md={12} className="text-light mb-1">
                            <h1 className="text-center mb-3">GraviLink &ndash; путь в мир искусственного интеллекта</h1>
                            <p className="lead text-justify opacity-90">
                                С GraviLink вы можете легко воспользоваться преимуществами искусственного интеллекта, не тратя времени на изучение программирования или глубокого погружения в сложные концепции науки о данных. Просто загрузите ваши данные и получите результаты, используя наш интуитивно понятный интерфейс и мощные алгоритмы машинного обучения.
                            </p>
                        </Col>
                    </div>
                </div>
                <div className="mb-5"> </div>
                <Container className="container mt-1 mb-5 p-4">
                    <Row className="justify-content-md-center body__bg_color text-light">
                        <Col md={4} className="ms-0 mt-5">
                            <h3 className="text-center">Простота</h3>
                            <Image src={Easy} thumbnail className="mt-3" />
                            <p className="text-center  mt-5 opacity-75">
                                Наш сервис предоставляет вам простые инструменты для загрузки данных и получения результатов.
                                Просто следуйте инструкциям, и вы сможете использовать мощь ИИ для своих задач.
                            </p>
                            <p className="lead text-center">
                                <NavLink as={NavLink} to="/covid" className="lead">
                                    <FaProjectDiagram className="me-2" />
                                    <strong>Попробуйте</strong>
                                </NavLink>
                            </p>
                        </Col>
                        <Col md={4} className="ms-0 mt-5">
                            <h3 className="text-center">Без программирования</h3>
                            <Image src={Nocode} thumbnail className="mt-3" />
                            <p className="text-center mt-5 opacity-75">
                                Забудьте о необходимости изучать языки программирования.
                                С GraviLink вы можете использовать современные технологии искусственного интеллекта,
                                не имея опыта в области программирования.
                            </p>
                            <p className="lead text-center">
                                <NavLink as={NavLink} to="/" className="lead">
                                    <FaCode className="me-2" />
                                    <strong>Подробности</strong>
                                </NavLink>
                            </p>
                        </Col>
                        <Col md={4} className="ms-0 mt-5">
                            <h3 className="text-center">Без специальных знаний</h3>
                            <Image src={Spec} thumbnail className="mt-3" />
                            <p className="text-center mt-5 opacity-75">
                                Не нужно быть экспертом по науке о данных.
                                GraviLink автоматически обучает модели машинного обучения на основе ваших данных,
                                обеспечивая простоту в использовании даже для тех, кто не имеет специализированных знаний.
                            </p>
                            <p className="lead text-center">
                                <NavLink as={NavLink} to="/" className="lead">
                                    <FaDatabase className="me-2" />
                                    <strong>Подробности</strong>
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container className="container mt-1 mb-5 p-4">
                    <Row className="justify-content-md-center body__bg_color text-light">
                        <Col md={12} className="mb-5">
                            <div className="image-header mb-5" >
                                <Image src={AML} class="img-fluid" />

                                <h1 className="text-center mb-3">Автоматическое машинное обучение</h1>
                            </div>
                            <p className="lead text-justify">
                                Сервис AML (Automatic Machine Learning) - это инновационный подход к созданию моделей машинного обучения.
                                Просто загрузите исходные данные и вам будет предоставлен рузультат в виде удобного веб-сервиса.
                                Основные функции AML включают:
                            </p>
                        </Col>
                        <Col md={12} className="mb-5 mt-3">
                            <dl className="row">
                                <dt className="col-sm-3 opacity-75">Преобработка исходных данных</dt>
                                <dd className="col-sm-9 lead">Наша система обеспечивает автоматизированный процесс предварительной обработки, загруженных в сервис AML,
                                    данных - (!!!тут нужно что-то написать!!!)
                                </dd>
                                <dt className="col-sm-3 opacity-75">Выбор и настройка алгоритма обучения модели</dt>
                                <dd className="col-sm-9 lead">AML проводит выбор, подходящего для конкретной задачи, алгоритма обучения.
                                    Кроме того, AML настраивает гиперпараметры модели для достижения наилучшей производительности и качества
                                </dd>
                                <dt className="col-sm-3 opacity-75">Обучение и оценка модели</dt>
                                <dd className="col-sm-9 lead">
                                    <p>AML в автоматическом режиме обучает модели. После завершения обучения, проводится тестирование, оценка производительности и точности.
                                        Выбирается наилучшая модель.
                                    </p>
                                </dd>
                                <dt className="col-sm-3 opacity-75">Автоматическое развертывание модели</dt>
                                <dd className="col-sm-9 lead">После выбора наилучшей модели AML автоматически разворачивает её в созданный проект (веб-сервис) для использовния</dd>
                            </dl>
                        </Col>
                        <Col md={12} className="mb-5 mt-3">
                            <p className="lead text-justify">
                                AML позволяет значительно ускорить процесс разработки моделей машинного обучения и
                                сократить затраты на подготовку данных и настройку моделей, делая машинное обучение доступным для широкого круга пользователей
                            </p>
                        </Col>
                    </Row>
                </Container>
                {/* 
                <div class="carousel-inner">
                    <img width="100%" height="auto" alt="" loading="lazy" decoding="async" data-nimg="1" className="" src={BG1} />
                    <div class="container">
                        <div class="carousel-caption text-start">
                        
                            <h1>AML</h1>
                            <p class="opacity-75">AML</p>
                            <p><a class="btn btn-lg btn-primary" href="#">AML</a></p>
                        </div>
                    </div>
                </div> */}


                <Container className="container mt-1 mb-5 p-4 text-light ">
                    <Row className="mb-5 mt-3">
                        <Row>
                            <Col xs={4} md={4} >
                                <Image src={World} thumbnail />
                            </Col>
                            <Col md={8} className="mb-5">
                                <h2 className="text-center mb-3">Наша цель -</h2>
                                <p className="lead text-justify opacity-75">
                                    сделать технологии искусственного интеллекта доступными и применимыми для широкого круга пользователей,
                                    независимо от их уровня технических навыков или специализации.
                                </p>
                            </Col>
                        </Row>
                    </Row>
                    <Row className="mb-5 mt-3">
                        <Row>
                            <Col md={8} className="mb-5">
                                <h2 className="text-center mb-3">Мы стремимся</h2>
                                <p className="lead text-justify opacity-75">
                                    сделать процесс работы с машинным обучением интуитивно понятным и легким для всех,
                                    предоставляя простые инструменты для загрузки данных и получения результатов через наш удобный интерфейс.
                                </p>
                            </Col>
                            <Col xs={4} md={4} >
                                <Image src={Endeavour} thumbnail />
                            </Col>
                        </Row>
                    </Row>
                    <Row className="mb-5 mt-3">
                        <Row>
                            <Col xs={4} md={4} >
                                <Image src={Partnership} thumbnail />
                            </Col>
                            <Col md={8} className="mb-5">
                                <h2 className="text-center mb-3">Партнерство и открытость</h2>
                                <p className="lead text-justify opacity-75">
                                    станьте частью нашего дружного сообщества и делитесь своими проектами. Найдите единомышленников и партнеров для совместных проектов и исследований в любой области, которая вас интересует.
                                </p>
                            </Col>
                        </Row>
                    </Row>
                </Container>

                <Covid />
            </main>
        </>
    );
}

export default Home;
