import { createContext, useState, useEffect } from "react";
import $api from './../api/api';
import $projectServiceApi from './../api/projectServiceApi';
import JWTStorage from "./JWTStorage";
import config from './config';
import $datasetServiceApi from "../api/datasetServiceApi";
import $modelServiceApi from "../api/modelServiceApi";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [isAppReady, setIsAppReady] = useState(true);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [tokenTest, setTokenTest] = useState('123'); 

  const handleLogOut = () => {
        setIsUserLogged(false);
        JWTStorage.deleteToken();
        setTokenTest(null)
  };

  const createUser = async (userData) => {
    $api.post('/users/', userData)
      .then((response) => {
        const { id, email, email_verified, is_admin } = response.data;
        console.log('Результат createUser response:', response.data);
        console.log('Результат createUser status:', response.status);
        console.log('Результат id:', id);
        console.log('Результат email:', email);
        console.log('Результат email_verified:', email_verified);
        console.log('Результат is_admin:', is_admin);
        return response
      })
      .catch((error) => {
        console.error('Error createUser response:', error);
      })
  };

  const requestCode = async (email) => {
    $api.get(`/request_code/${email}`)
      .then((response) => {
        const { message } = response.data;
        console.log('Результат requestCode response:', response.data);
        console.log('Результат requestCode status:', response.status);
        console.log('Результат requestCode message:', message);
        return 'response 200'
        //inMemoryJWT.setToken(accessToken, accessTokenExpiration);
        //setIsUserLogged(true);
      })
      .catch((error) => {
        console.error('Error getTokenNew response:', error);
        return 'error'
      })
  };

  const getToken = async (tokenData) => {
    $api.post('/token', tokenData)
      .then((response) => {
        const { access_token, token_type } = response.data;
        console.log('Результат getToken response:', response.data);
        console.log('Результат getToken status:', response.status);
        console.log('Результат getToken access_token:', access_token);
        console.log('Результат getToken token_type:', token_type);
        JWTStorage.setToken(access_token, 10000)
        //localStorage.setItem(config.LOGOUT_STORAGE_KEY, Date.now());
        setTokenTest(access_token);
        setIsUserLogged(true);
        return response
        //inMemoryJWT.setToken(accessToken, accessTokenExpiration);
      })
      .catch((error) => {
        console.error('Error getTokenNew response:', error);
      })
  };

  const getUserById = async (userId, Auth) => {
    $api.get(`/users/${userId}`, {
      headers: { Authorization: `Bearer ${Auth}` }
    })
      .then((response) => {
        const { id, email, email_verified, is_admin } = response.data;
        console.log('Результат getUserById response:', response.data);
        console.log('Результат getUserById status:', response.status);
        console.log('Результат getUserById id:', id);
        console.log('Результат getUserById email:', email);
        console.log('Результат getUserById email_verified:', email_verified);
        console.log('Результат getUserById is_admin:', is_admin);
      })
      .catch((error) => {
        console.error('Произошла ошибка:', error);
      })
  };

  const deleteUserById = async (userId, Auth) => {
    $api.delete(`/users/${userId}`, {
      headers: { Authorization: `Bearer ${Auth}` }
    })
      .then((response) => {
        const { id, email, email_verified, is_admin } = response.data;
        console.log('Результат deleteUserById response:', response.data);
        console.log('Результат deleteUserById status:', response.status);
        console.log('Результат deleteUserById id:', id);
        console.log('Результат deleteUserById email:', email);
        console.log('Результат deleteUserById email_verified:', email_verified);
        console.log('Результат deleteUserById is_admin:', is_admin);
      })
      .catch((error) => {
        console.error('Произошла ошибка:', error);
      })
  };

  const updateUserById = async (userId, Auth, newEmail) => {
    $api.put(`/users/${userId}`, {
      email: newEmail,
    }, {
      headers: { Authorization: `Bearer ${Auth}` }
    })
      .then((response) => {
        const { id, email, email_verified, is_admin } = response.data;
        console.log('Результат updateUserById response:', response.data);
        console.log('Результат updateUserById status:', response.status);
        console.log('Результат updateUserById id:', id);
        console.log('Результат updateUserById email:', email);
        console.log('Результат updateUserById email_verified:', email_verified);
        console.log('Результат updateUserById is_admin:', is_admin);
      })
      .catch((error) => {
        console.error('Произошла ошибка:', error);
      })
  };
  
  useEffect(() => {
    //const currentDate = Math.floor(Math.random() * 1000);
    const tokenValue = JWTStorage.getToken();
    setTokenTest(tokenValue);
    console.log("tokenValue:" + tokenValue)
    if (tokenValue === 'logout') {
      setIsUserLogged(false);
    } else {
      setIsUserLogged(true);
    }
  }, []);

  useEffect(() => {
    const handlePersistedLogOut = (event) => {
      console.log("event:" + event.key)
      if (event.key === config.LOGOUT_STORAGE_KEY) {
        JWTStorage.deleteToken();
        setIsUserLogged(false);
      }
    };

    window.addEventListener("storage", handlePersistedLogOut);

    return () => {
      window.removeEventListener("storage", handlePersistedLogOut);
    };
  }, []);

  const handleProjectCreate = async (nameProject, domain_id, type_id) => {
    $projectServiceApi.post(`/projects/`, {
      name: nameProject,
      domain_id: 1,
      type_id: 1,
    }, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name, dataset_id, model_id, domain_id, type_id, owner_id, is_shared } = response.data;
        console.log('Результат handleProjectCreate response:', response.data);
        console.log('Результат handleProjectCreate status:', response.status);
        console.log('Результат handleProjectCreate id:', id);
        console.log('Результат handleProjectCreate name:', name);
        console.log('Результат handleProjectCreate dataset_id:', dataset_id);
        console.log('Результат handleProjectCreate model_id:', model_id);
        console.log('Результат handleProjectCreate domain_id:', domain_id);
        console.log('Результат handleProjectCreate type_id:', type_id);
        console.log('Результат handleProjectCreate owner_id:', owner_id);
        console.log('Результат handleProjectCreate is_shared:', is_shared);
      })
      .catch((error) => {
        console.error('Произошла handleProjectCreate ошибка:', error);
      })
  };

  const handleProjectRead = async (project_id) => {
    $projectServiceApi.get(`/projects/${project_id}`, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name, dataset_id, model_id, domain_id, type_id, owner_id, is_shared } = response.data;
        console.log('Результат handleProjectRead response:', response.data);
        console.log('Результат handleProjectRead status:', response.status);
        console.log('Результат handleProjectRead id:', id);
        console.log('Результат handleProjectRead name:', name);
        console.log('Результат handleProjectRead dataset_id:', dataset_id);
        console.log('Результат handleProjectRead model_id:', model_id);
        console.log('Результат handleProjectRead domain_id:', domain_id);
        console.log('Результат handleProjectRead type_id:', type_id);
        console.log('Результат handleProjectRead owner_id:', owner_id);
        console.log('Результат handleProjectRead is_shared:', is_shared);
        //inMemoryJWT.setToken(accessToken, accessTokenExpiration);
        //setIsUserLogged(true);
      })
      .catch((error) => {
        console.error('Error handleProjectRead response:', error);
      })
  };

  const handleProjectUpdate = async (project_id, nameProject) => {
    $projectServiceApi.put(`/projects/${project_id}`, {
      name: nameProject,
    }, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name, dataset_id, model_id, domain_id, type_id, owner_id, is_shared } = response.data;
        console.log('Результат handleProjectUpdate response:', response.data);
        console.log('Результат handleProjectUpdate status:', response.status);
        console.log('Результат handleProjectUpdate id:', id);
        console.log('Результат handleProjectUpdate name:', name);
        console.log('Результат handleProjectUpdate dataset_id:', dataset_id);
        console.log('Результат handleProjectUpdate model_id:', model_id);
        console.log('Результат handleProjectUpdate domain_id:', domain_id);
        console.log('Результат handleProjectUpdate type_id:', type_id);
        console.log('Результат handleProjectUpdate owner_id:', owner_id);
        console.log('Результат handleProjectUpdate is_shared:', is_shared);
        //inMemoryJWT.setToken(accessToken, accessTokenExpiration);
        //setIsUserLogged(true);
      })
      .catch((error) => {
        console.error('Error handleProjectUpdate response:', error);
      })
  };

  const handleProjectPatch = async (project_id) => {
    $projectServiceApi.patch(`/projects/${project_id}`, {}, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name, dataset_id, model_id, domain_id, type_id, owner_id, is_shared } = response.data;
        console.log('Результат handleProjectPatch response:', response.data);
        console.log('Результат handleProjectPatch status:', response.status);
        console.log('Результат handleProjectPatch id:', id);
        console.log('Результат handleProjectPatch name:', name);
        console.log('Результат handleProjectPatch dataset_id:', dataset_id);
        console.log('Результат handleProjectPatch model_id:', model_id);
        console.log('Результат handleProjectPatch domain_id:', domain_id);
        console.log('Результат handleProjectPatch type_id:', type_id);
        console.log('Результат handleProjectPatch owner_id:', owner_id);
        console.log('Результат handleProjectPatch is_shared:', is_shared);
        //inMemoryJWT.setToken(accessToken, accessTokenExpiration);
        //setIsUserLogged(true);
      })
      .catch((error) => {
        console.error('Error handleProjectPatch response:', error);
      })
  };


  const handleProjectDelete = async (project_id) => {
    $projectServiceApi.delete(`/projects/${project_id}`, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name, dataset_id, model_id, domain_id, type_id, owner_id, is_shared } = response.data;
        console.log('Результат handleProjectDelete response:', response.data);
        console.log('Результат handleProjectDelete status:', response.status);
        console.log('Результат handleProjectDelete id:', id);
        console.log('Результат handleProjectDelete name:', name);
        console.log('Результат handleProjectDelete dataset_id:', dataset_id);
        console.log('Результат handleProjectDelete model_id:', model_id);
        console.log('Результат handleProjectDelete domain_id:', domain_id);
        console.log('Результат handleProjectDelete type_id:', type_id);
        console.log('Результат handleProjectDelete owner_id:', owner_id);
        console.log('Результат handleProjectDelete is_shared:', is_shared);
        //inMemoryJWT.setToken(accessToken, accessTokenExpiration);
        //setIsUserLogged(true);
      })
      .catch((error) => {
        console.error('Error handleProjectDelete response:', error);
      })
  };

  const handleModelRead = async (project_id) => {
    $modelServiceApi.get(`/models/${project_id}`, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, status } = response.data;
        console.log('Результат handleModelRead response:', response.data);
        console.log('Результат handleModelRead status:', response.status);
        console.log('Результат handleModelRead id:', id);
        console.log('Результат handleModelRead status:', status);
      })
      .catch((error) => {
        console.error('Error handleModelRead response:', error);
      })
  };

  const handleModelTrain = async (project_id) => {
    $modelServiceApi.post(`/models/train/${project_id}`, {}, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, status } = response.data;
        console.log('Результат handleModelTrain response:', response.data);
        console.log('Результат handleModelTrain status:', response.status);
        console.log('Результат handleModelTrain id:', id);
        console.log('Результат handleModelTrain status:', status);
      })
      .catch((error) => {
        console.error('Error handleModelTrain response:', error);
      })
  };


  const handleModelCreate = async (id_model) => {
    $modelServiceApi.post(`/models/`,{
      id: id_model,
    }, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, status } = response.data;
        console.log('Результат handleModelCreate response:', response.data);
        console.log('Результат handleModelCreate status:', response.status);
        console.log('Результат handleModelCreate id:', id);
        console.log('Результат handleModelCreate status:', status);
      })
      .catch((error) => {
        console.error('Error handleModelCreate response:', error);
      })
  };

  const handleModelUpdate = async (project_id) => {
    $modelServiceApi.put(`/models/${project_id}`, {}, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, status } = response.data;
        console.log('Результат handleModelUpdate response:', response.data);
        console.log('Результат handleModelUpdate status:', response.status);
        console.log('Результат handleModelUpdate id:', id);
        console.log('Результат handleModelUpdate status:', status);
      })
      .catch((error) => {
        console.error('Error handleModelUpdate response:', error);
      })
  };

  const handleModelDelete = async (project_id) => {
    $modelServiceApi.put(`/models/${project_id}`, {}, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, status } = response.data;
        console.log('Результат handleModelDelete response:', response.data);
        console.log('Результат handleModelDelete status:', response.status);
        console.log('Результат handleModelDelete id:', id);
        console.log('Результат handleModelDelete status:', status);
      })
      .catch((error) => {
        console.error('Error handleModelDelete response:', error);
      })
  };

  const handleModelApply = async (project_id, file) => {
    $modelServiceApi.post(`/models/${project_id}`,{
      file,
    }, {
      headers: { 
        Authorization: `Bearer ${tokenTest}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        const { id, status } = response.data;
        console.log('Результат handleModelApply response:', response.data);
        console.log('Результат handleModelApply status:', response.status);
        console.log('Результат handleModelApply id:', id);
        console.log('Результат handleModelApply status:', status);
      })
      .catch((error) => {
        console.error('Error handleModelApply response:', error);
      })
  };


  const handleDomainsCreate = async (nameDomains) => {
    $datasetServiceApi.post(`/domains/`, {
      name: nameDomains,
    }, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name } = response.data;
        console.log('Результат handleDomainsCreate response:', response.data);
        console.log('Результат handleDomainsCreate status:', response.status);
        console.log('Результат handleDomainsCreate id:', id);
        console.log('Результат handleDomainsCreate name:', name);
      })
      .catch((error) => {
        console.error('Произошла handleDomainsCreate ошибка:', error);
      })
  };
 
  const handleTaskTypesCreate = async (nameTaskTypes) => {
    $datasetServiceApi.post(`/task_types/`, {
      name: nameTaskTypes,
    }, {
      headers: { Authorization: `Bearer ${tokenTest}` }
    })
      .then((response) => {
        const { id, name } = response.data;
        console.log('Результат handleTaskTypesCreate response:', response.data);
        console.log('Результат handleTaskTypesCreate status:', response.status);
        console.log('Результат handleTaskTypesCreate id:', id);
        console.log('Результат handleTaskTypesCreate name:', name);
      })
      .catch((error) => {
        console.error('Произошла handleTaskTypesCreate ошибка:', error);
      })
  };


  return (
    <AuthContext.Provider
      value={{
        //data,
        // handleFetchProtected,
        // handleSignUp,
        // handleSignIn,
        // handleLogOut,
        tokenTest,
        isAppReady,
        isUserLogged,
        createUser,
        requestCode,
        getToken,
        getUserById,
        deleteUserById,
        updateUserById,
        handleLogOut,
        handleProjectCreate,
        handleProjectRead,
        handleProjectUpdate,
        handleProjectPatch,
        handleProjectDelete,
        handleModelCreate,
        handleModelRead,
        handleModelDelete,
        handleModelTrain,
        handleModelUpdate,
        handleModelApply,
        handleDomainsCreate,
        handleTaskTypesCreate,
      }}
    >
      {isAppReady ? (
        children
      ) : (
        <div className=''>
          {/* <Circle /> */}
          APP NOT WORK
        </div>
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
