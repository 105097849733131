

const pricingList = [
  {
    id: 1,
    name: 'Basic Plan',
    price: '$19.99/month',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel fermentum massa.',
  },
  {
    id: 2,
    name: 'Standard Plan',
    price: '$39.99/month',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel fermentum massa.',
  },
  {
    id: 3,
    name: 'Premium Plan',
    price: '$159.99/month',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel fermentum massa.',
  },
];

export default pricingList;
