import { Container, Row, Col, Form } from 'react-bootstrap';

const Contacts = () => {
  return (
    <Container className="mt-5 text-light bg-dark">

      <Row>
        <Col className="m-5">
          <h2>Свяжитесь с нами</h2>
          <p className="opacity-75">Желаете сотрудничать с нами? Мы рады рассмотреть ваше предложение!</p>
        </Col>
      </Row>

      <Row className="m-5">
        <Col md={6}>
          <h4>Адрес:</h4>
          <p className="opacity-75">город Санкт-Петербург<br /> пр-кт Малый В.О., д. 62 к. 1 литера А<br />пом/офис 178н/2.5</p>
        </Col>
        <Col md={6}>
          <h4>Электронная почта:</h4>
          <p className="opacity-75">info@gravilink.com</p>
        </Col>
      </Row>

      <Row className="m-5">
        <Col md={6}>
          <h4>Телефон:</h4>
          <p className="opacity-75">+7 952 375 3105</p>
        </Col>
        <Col md={6}>
          <form className="mb-5">
            <h4>Отправте нам сообщение</h4>
            <div className="mb-3">
                <Form.Label className="text-body-secondary">
                  Оставте контактные данные и мы свяжемся с вами
                </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Введите текстовую информацию тут..." />
              </div>
            <button className="btn btn-primary" type="button">Отправить</button>
          </form>


          {/* <h4>Social Media:</h4>
          <p>
            <a href="https://www.facebook.com/example" target="_blank" rel="noopener noreferrer">Facebook</a><br />
            <a href="https://www.twitter.com/example" target="_blank" rel="noopener noreferrer">Twitter</a><br />
            <a href="https://www.linkedin.com/company/example" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </p> */}
        </Col>
      </Row>

    </Container>
  );
};

export default Contacts;
