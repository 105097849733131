import { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const UserProfilePage = ({ user }) => {
  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    // Добавьте другие поля профиля по необходимости
  });

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    // Сбрасываем изменения в данные пользователя
    setUserData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      // Сбросьте другие поля профиля по необходимости
    });
  };

  const handleSaveChanges = () => {
    // Здесь можно добавить логику сохранения изменений на сервере
    // и обновление состояния пользователя
    setEditMode(false);
    // Здесь также можно обновить состояние пользователя
    // с новыми данными из userData
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  return (
    <Container className="mt-3">
      <h2>User Profile</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            name="firstName"
            value={userData.firstName}
            onChange={handleChange}
            readOnly={!editMode}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
            readOnly={!editMode}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            readOnly={!editMode}
          />
        </Form.Group>

        {editMode ? (
          <>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button variant="secondary" className="ms-2" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={handleEdit}>
            Edit Profile
          </Button>
        )}
      </Form>
    </Container>
  );
};

export default UserProfilePage;
