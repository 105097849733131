import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import project01BigCovid from "./../../../img/projects/1200x400_COVID.jpg";
import './style.css';

class ProjectInfo {
  constructor() {
    this.id = "1";
    this.name = "covid-ct.zip";
    this.status = "ready";
  }
}

const Covid = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [result, setResult] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [captions, setCaptions] = useState([]);
  const fetchProjects = async () => {
    try {
      const response = await fetch("https://aml.gravilink.com/get_all_projects");
      const data = await response.json();
      setProjectsData(data);

      if (data.length > 0) {
        setSelectedProject(data[0].id);
        console.log("Selected File:", data[0].id);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      if (error instanceof Error) {
        alert('Error fetching projects: ' + error.message);
      } else {
        alert('An unknown error occurred.');
      }
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    const onGetResult = async () => {
      if (!selectedFile) {
        return;
      }

      const selectedProjectInstance = new ProjectInfo();
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await fetch(`https://aml.gravilink.com/infer_image/${selectedProjectInstance.id}`, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error ${response.status}: ${errorText}`);
        }

        const data = await response.json();
        if (data.class !== undefined && data.score !== undefined) {
          const classDescription = data.class === 0 ? 'здоров' : 'болен';
          const newCaption = `${classDescription}, уверенность: ${Math.round(data.score * 100)}%`;
          // const newCaption = `класс: ${data.class}, ${Math.round(data.score * 100)}%`;
          const fileName = selectedFile.name;
          setCaptions(prevCaptions => [...prevCaptions, `${fileName}: ${newCaption}`]);
          setResult(`${fileName}: ${newCaption}`);
          setUploadedImages(prevImages => [...prevImages, selectedFile]);
        } else {
          setResult("Invalid response format");
        }
      } catch (error) {
        console.error('Error fetching inference result:', error);
      }
    };

    onGetResult();
  }, [selectedFile]);

  return (
    <Container className="mt-3 text-center text-light">
      <div className="image-header mb-5" >
        <Image src={project01BigCovid} class="img-fluid" />
        <h1 className="text-center mb-3">Выявление COVID-19</h1>
      </div>
      <Row>
        <Col>
          <p className="mt-3">Загрузите изображения КТ-сканирования легких, для автоматизированного выявления в организме человека вируса SARS-CoV-2</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <p>На основе 10 позитивных и 10 негативных изображений.</p>
        </Col>
      </Row>

      <Row className="mt-3">
        {uploadedImages.map((image, index) => (
          <Col key={index} md={6} className="mb-4">
            <div className="image-preview">
              <Image src={URL.createObjectURL(image)} fluid />
              <p >{captions[index]}</p>
            </div>
          </Col>
        ))}
      </Row>

      <div>
        <Form.Label htmlFor="project-name">Выберите проект</Form.Label>
        <Form.Control
          as="select"
          name="project-name"
          id="project-name"
          value={selectedProject}
          onChange={(e) => setSelectedProject(e.target.value)}
        >
          {projectsData.filter(project => project.status === "ready").map(project => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </Form.Control>
      </div>
      <Row className="mt-3">
        <Col>
          <div>
            <Form.Control type="file" accept=".png, .jpg" id="upload-image" onChange={(e) => {
              const files = e.target.files;
              if (files && files.length > 0) {
                setSelectedFile(files[0]);
                console.log("Selected File:", files[0]);
              }
            }} />
          </div>

        </Col>
      </Row>
    </Container>
  );
};

export default Covid;
