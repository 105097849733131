import React from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import pricingList from './../../helpers/pricingList'

const Billing = () => {
    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity()) {
            console.log('Форма валидна. Выполняем отправку данных на сервер.');
        } else {
            console.log('Форма не валидна. Пожалуйста, заполните все обязательные поля.');
        }
    };
    // TODO secuirity
    const selectedPlan = pricingList[0];
    return (
        <Container className="text-center mb-4 text-light bg-dark p-3">
            <Container className="mt-3">
                <Row>
                    <Col className="text-center">
                        <h3>Вы выбрали:</h3>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={{ span: 4, offset: 4 }} className="d-flex justify-content-center">
                        <Card className="left__menu_bg text-light">
                            <Card.Header as="h5">{selectedPlan.name}</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-gold">{selectedPlan.price}</Card.Title>
                                <Card.Text>{selectedPlan.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Row className="g-5">
                <Col md={12} lg={12}>
                    <h4 className="mb-3">Billing address</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit}>
                        <Row className="g-3">
                            <Col sm={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control type="text" placeholder="Иван" required />
                                    <Form.Control.Feedback type="invalid">Valid first name is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control type="text" placeholder="Иванов" required />
                                    <Form.Control.Feedback type="invalid">Valid last name is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="username">
                                    <Form.Label>Username</Form.Label>
                                    <div className="input-group has-validation">
                                        <span className="input-group-text">@</span>
                                        <Form.Control type="text" placeholder="Username" required />
                                        <Form.Control.Feedback type="invalid">Your username is required.</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="you@example.com" required />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address for shipping updates.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="Address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder="1 Main St" required />
                                    <Form.Control.Feedback type="invalid">Valid Address is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="Address2">
                                    <Form.Label>Address 2 (Optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Apartment or suite" required />
                                    <Form.Control.Feedback type="invalid">Valid Address2 is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select required>
                                        <option value="">Choose...</option>
                                        <option>United States</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a valid country.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select required>
                                        <option value="">Choose...</option>
                                        <option>California</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid state.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="zip">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control type="text" placeholder="" required />
                                    <Form.Control.Feedback type="invalid">
                                        Zip code required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr className="my-4" />
                        <Row className="g-5">
                            <h4 className="mb-3">Payment</h4>
                            <div className="my-3">
                                <Form.Check
                                    type="radio"
                                    id="credit"
                                    name="paymentMethod"
                                    label="Credit card"
                                    checked
                                    onChange={() => { }}
                                    required
                                />

                                <Form.Check
                                    type="radio"
                                    id="debit"
                                    name="paymentMethod"
                                    label="Debit card"
                                    onChange={() => { }}
                                    required
                                />

                                <Form.Check
                                    type="radio"
                                    id="paypal"
                                    name="paymentMethod"
                                    label="PayPal"
                                    onChange={() => { }}
                                    required
                                />
                            </div>
                            <Row className="gy-3">
                                <Col md={6}>
                                    <Form.Group controlId="cc-name">
                                        <Form.Label>Name on card</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Label className="text-body-secondary">Full name as displayed on card</Form.Label>
                                        <Form.Control.Feedback type="invalid">Name on card is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="cc-number">
                                        <Form.Label>Credit card number</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Control.Feedback type="invalid">Credit card number is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group controlId="cc-expiration">
                                        <Form.Label>Expiration</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Control.Feedback type="invalid">Expiration date required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group controlId="cc-cvv">
                                        <Form.Label>CVV</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Control.Feedback type="invalid">Security code required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                        <hr className="my-4" />
                        <Button variant="primary" size="lg" type="submit">
                            Continue to checkout
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Billing;
